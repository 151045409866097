import { useState } from "react";
import { useTranslation } from "react-i18next";

function MXEligibleScreen({
                            stepKey,
                            travelReason,
                            citizenshipCountry,
                            currentStep,
                            setCurrentStep,
                          }: {
  stepKey: string;
  travelReason: string;
  citizenshipCountry: string;
  currentStep: string;
  setCurrentStep: Function;
}) {
  const { t } = useTranslation();

  const [haveVisa, setHaveVisa] = useState<boolean | null>(null);
  const [planTravel, setPlanTravel] = useState<"air" | "bus" | null>(null);
  const [isEligible, setIsEligible] = useState<boolean>(true);

  const haveCanadaVisitor = (action: "YES" | "NO") => {
    setHaveVisa(action === "YES");
  };

  const handlePlanTravel = (via: "air" | "bus") => {
    setPlanTravel(via);
  };
  const handleContinue = () => {
    if (haveVisa && planTravel === "air") {
      setCurrentStep("ELIGIBLE");
      window.scrollTo(0, 0);
    } else {
      setIsEligible(false);
      window.scrollTo(0, 0);
    }

    if (!isEligible) {
      const redirectURL = "https://ivisa.govassist.com/canada/apply-now";
      window.location.href = redirectURL;
    }
  };

  const handleCancel = () => {
    setIsEligible(true);
  };

  return stepKey !== currentStep ? null : (
      <div className="!max-w-screen">
        {
            isEligible && (
                <div className="mexican-eligible-screen !flex !flex-col !gap-6 !text-lg !w-full !bg-white !rounded-3xl !p-8">
                  <div className="!text-center !text-[36px] !leading-none !text-[#101727] !font-medium !font-sans">
                    { t("MX_TITLE_1") }
                  </div>

                  <div className="!text-left !text-base !text-[#616161] !font-roboto-sans" dangerouslySetInnerHTML={{ __html: t("MX_TITLE_1_DESCRIPTION") }}>
                  </div>

                  <div className="!text-left !text-[28px] !leading-[30px] !font-medium !font-sans">
                    { t("MX_TITLE_2") }
                  </div>

                  <ul className="!text-left !list-disc !list-outside !px-4 !text-[#616161] !font-roboto-sans !font-light">
                    <li className="!font-roboto-sans !font-light">{ t("MX_TITLE_2_DESCRIPTION_1") }</li>
                    <li className="!font-roboto-sans !font-light">{ t("MX_TITLE_2_DESCRIPTION_2") }</li>
                    <li className="!font-roboto-sans !font-light">{ t("MX_TITLE_2_DESCRIPTION_3") }</li>
                    <li className="!font-roboto-sans !font-light">{ t("MX_TITLE_2_DESCRIPTION_4") }</li>
                    <li className="!font-roboto-sans !font-light">{ t("MX_TITLE_2_DESCRIPTION_5") }</li>
                  </ul>

                  <div className="!text-left !text-[#8A8A8A] !text-sm !font-roboto-sans !font-semibold">
                    { t("MX_TITLE_2_FOOTER") }
                  </div>

                  <div className="!flex !flex-col !w-full !border-b-2 !border-t-2 !py-8 !gap-6 !font-sans">
            <span className="!text-left !font-medium !text-[22px] !leading-[30px] !text-black !font-sans">
              { t("MX_TITLE_3") }
            </span>
                    <div className="flex flex-row items-center justify-start gap-4">
                      <button
                          className={`${
                              haveVisa === true
                                  ? "bg-[#0067FF] text-white hover:bg-blue-950"
                                  : "bg-[#EFF4FA] text-[#64748B] hover:bg-slate-200"
                          } font-sans ring-2 text-base rounded-full px-8 py-2 shadow-md`}
                          onClick={() => setHaveVisa(true)}
                      >
                        {t("YES")}
                      </button>
                      <button
                          className={`${
                              haveVisa === false
                                  ? "bg-[#0067FF] text-white hover:bg-blue-950"
                                  : "bg-[#EFF4FA] text-[#64748B] hover:bg-slate-200"
                          } font-sans ring-2 text-base rounded-full px-8 py-2 shadow-md`}
                          onClick={() => setHaveVisa(false)}
                      >
                        {t("NO")}
                      </button>
                    </div>
                  </div>

                  {haveVisa && (
                      <div className="!flex !flex-col !w-full !border-b-2 !pt-4 !pb-8 !gap-6 !justify-start !items-start !font-sans">
              <span className="!text-left !font-medium !text-[22px] !leading-[30px] !text-black !font-sans">
                { t("MX_TITLE_4") }
              </span>

                        <div>
                          <button
                              className={`${
                                  planTravel === "air"
                                      ? "bg-[#0067FF] text-white hover:bg-blue-950 ring-[#0067FF]"
                                      : "bg-[#EFF4FA] text-[#64748B] hover:bg-slate-200 ring-gray-200"
                              } ring-2 text-base rounded-full px-8 py-2 shadow-md !font-sans`}
                              onClick={() => handlePlanTravel("air")}
                          >
                            {t("MX_BY_AIR")}
                          </button>
                        </div>

                        <div>
                          <button
                              className={`${
                                  planTravel === "bus"
                                      ? "bg-[#0067FF] text-white hover:bg-blue-950 ring-[#0067FF]"
                                      : "bg-[#EFF4FA] text-[#64748B] hover:bg-slate-200 ring-gray-200"
                              } ring-2 text-base rounded-full px-8 py-2 shadow-md !font-sans`}
                              onClick={() => handlePlanTravel("bus")}
                          >
                            {t("MX_BY_BUS")}
                          </button>
                        </div>

                      </div>
                  )}

                  <div className="!flex !flex-col !items-center !justify-center !w-full !py-6">

                    <button onClick={() => handleContinue()} className="flex flex-row items-center gap-1 justify-between py-2 px-16 bg-[#0067FF] text-white rounded-full ">
              <span className="font-medium !font-sans">
                {t("CONTINUE")}
              </span>
                      <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="#000000" d="M12.6 12L8 7.4L9.4 6l6 6l-6 6L8 16.6z"></path>
                      </svg>
                    </button>

                  </div>

                </div>
            )
        }

        {
            !isEligible && (
                <div className="mexican-non-eligible-screen !flex !flex-col !items-center !justify-center !w-full !gap-6 !bg-white !rounded-3xl !p-8 !font-display">

                  <div className="!text-[36px] !leading-[40px] !font-medium !text-center !font-sans">
                    { t("MX_NON_ELIGIBLE_TITLE") }
                  </div>

                  <p className="flex w-full text-left text-[18px] leading-[20px] text-[#616161] font-normal !font-display mt-4">
                    { t("MX_NON_ELIGIBLE_DESC_1") }
                  </p>

                  <p className="flex w-full text-left  text-[18px] leading-[20px] text-[#616161] font-light !font-display"
                     dangerouslySetInnerHTML={{ __html: t("MX_NON_ELIGIBLE_DESC_2") }}
                  ></p>

                  <p className="flex w-full text-left text-[18px] leading-[20px] text-[#616161] font-light !font-display">
                    { t("MX_NON_ELIGIBLE_DESC_3") }
                  </p>

                  <div className="flex flex-col md:flex-row justify-center md:justify-evenly items-center gap-4 md:gap-8 mt-4 font-sans font-medium text-base">

                    <div className="w-full md:w-[80%]">
                      <button onClick={() => handleCancel()} className="flex flex-col w-full min-w-[250px] items-center gap-1 justify-between py-3 px-16 bg-[#EFF4FA] text-[#64748B] hover:bg-slate-200 ring-gray-200 rounded-full shadow-md !font-sans">
                        {t("CANCEL")}
                      </button>
                    </div>

                    <div className="w-full md:w-[80%]">
                      <button onClick={() => handleContinue()} className="!w-full flex flex-row items-center gap-1 justify-between py-2 px-16 bg-[#0067FF] text-white rounded-full shadow-md">
                  <span className="font-medium !font-sans">
                    {t("CONTINUE")}
                  </span>
                        <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                          <path fill="#000000" d="M12.6 12L8 7.4L9.4 6l6 6l-6 6L8 16.6z"></path>
                        </svg>
                      </button>
                    </div>

                  </div>

                </div>
            )
        }
      </div>
  );
}

export default MXEligibleScreen;
