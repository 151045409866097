import { createContext, useState } from "react";

const initialData = {
  formUrl: "/apply",
  gclid: "",
  supportUrl: "/support",
  features: {
    showPricePage: false,
  }
};

export const ConfigContext = createContext<{ config: any; setConfig: any }>({
  config: initialData,
  setConfig: null,
});

export const ConfigProvider = ({ children }: { children: any }) => {
  const [config, setConfig] = useState(initialData);

  return (
    <ConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};
