import { useContext, useEffect, useState, useMemo, FunctionComponent, SVGProps } from "react";
import ReactFlagsSelect from "react-flags-select";
import { ConfigContext } from "../../contexts/ConfigContext";
import { isCountryETAEligible } from "../../utils/eta";
import { useTranslation } from "react-i18next";
import Select, {
  components,
  DropdownIndicatorProps,
  OptionProps,
  PlaceholderProps,
  SingleValueProps,
  StylesConfig, 
  GroupBase
} from "react-select";

import { ReactComponent as SelectIcon } from "../../svg/select.svg";
import { ReactComponent as BusinessIcon } from "../../svg/business.svg";
import { ReactComponent as TourismIcon } from "../../svg/tourism.svg";
import { ReactComponent as TransitIcon } from "../../svg/transit.svg";
import { ReactComponent as RightArrowIcon } from "../../svg/arrow-right.svg";
import { ReactComponent as Check } from "../../svg/check.svg";

interface Reason {
  value: string;
  label: string;
  icon: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
}

function InitialScreen({
  stepKey,
  setTravelReason,
  setCitizenshipCountry,
  travelReason,
  citizenshipCountry,
  currentStep,
  setCurrentStep,
}: {
  stepKey: string;
  setTravelReason: Function;
  setCitizenshipCountry: Function;
  travelReason: string;
  citizenshipCountry: string;
  currentStep: string;
  setCurrentStep: Function;
}) {
  const [continueClicked, setContinueClicked] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const {
    config: { detectedCountry = "", fallbackUrl },
  } = useContext(ConfigContext);

  const { t } = useTranslation();
  const reasons: Reason[] = useMemo(
    () => [
      { value: "tourism", label: t("TOURISM"), icon: TourismIcon },
      { value: "business", label: t("BUSINESS"), icon: BusinessIcon },
      { value: "transit", label: t("TRANSIT"), icon: TransitIcon },
    ],
    [t]
  );

  const selectedReason = useMemo(
    () => reasons.find((reason) => reason.value === travelReason),
    [reasons, travelReason]
  );
  const Option: React.FC<OptionProps<Reason>> = (props) => (
    <components.Option {...props}>
      <div className="flex flex-row items-center w-full justify-between">
        <div 
          className={`flex flex-row font-medium ${
            props.data.value === selectedReason?.value ? 'text-[#71717A]' : 'text-[#D3D3D6]' 
          }`}
        >
          <props.data.icon 
            className="w-6 h-6 mr-2" 
            fill={props.data.value === selectedReason?.value ? '#0067FF' : '#D3D3D6'}
          />
          {props.data.label}
        </div>
        {props.data.value === selectedReason?.value && <Check />}
      </div>
    </components.Option>
  );
  

  const DropdownIndicator: React.FC<DropdownIndicatorProps<Reason>> = (
    props
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <i className="reasons-icon" />
      </components.DropdownIndicator>
    );
  };

  const SingleValue: React.FC<SingleValueProps<Reason>> = ({
    children,
    ...props
  }) => (
    <components.SingleValue {...props}>
      {selectedReason?.icon && <selectedReason.icon className="w-6 h-6 mr-2" fill="#0067FF" />}
      <span className="font-medium text-base">{children}</span>
    </components.SingleValue>
  );

  const Placeholder: React.FC<PlaceholderProps<Reason>> = (props) => {
    return (
      <components.Placeholder {...props}>
        <div className="flex flex-row">
          {SelectIcon && <SelectIcon className="w-6 h-6 mr-2" fill="#0067FF"/>}
          <span className="text-[#7D8A9C]  font-medium text-base">{t("SELECT_REASON")}</span>
        </div>
      </components.Placeholder>
    );
  };

  const updateErrors = () => {
    let _errors: string[] = [];
    if (!travelReason) _errors = [..._errors, t("TRAVEL_REASON_ERROR")];
    if (!citizenshipCountry) _errors = [..._errors, t("CITIZENSHIP_ERROR")];

    setErrors(_errors);

    return _errors;
  };

  const handleContinueClick = () => {

    setContinueClicked(true);
    const _errors = updateErrors();

    if (_errors.length === 0) {
      if (["PR", "US"].includes(citizenshipCountry)) {
        setCurrentStep("USA-ELIGIBLE");
        return;
      }

      if (["MX"].includes(citizenshipCountry)) {
        setCurrentStep("MX-ELIGIBLE");
        return;
      }

      if (isCountryETAEligible(citizenshipCountry)) {
        setCurrentStep("ELIGIBLE");
      } else {
        if(fallbackUrl) {
          window.location.href=fallbackUrl
        } else {
          setCurrentStep("NON-ELIGIBLE");
        }

      }
    }
  };

  const handleReasonChange = (reason: Reason) => setTravelReason(reason.value);

  const reasonsStyle: StylesConfig<Reason, false, GroupBase<Reason>> = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#F9FAFB' : '#FFF',
      color: '#71717A',
      fontWeight: '500',
      '&:hover': {
        backgroundColor: '#F9FAFB',
        cursor: 'pointer'
      },
    }),
    valueContainer: (base) => ({
      ...base,
      height: "44px",
    }),
    singleValue: (base) => ({
      ...base,
      display: "flex",
      flexDirection: "row",
      gap: "8px",
      alignItems: "center",
    })
  };
  

  useEffect(() => {
    if (continueClicked) updateErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travelReason, citizenshipCountry]);

  useEffect(() => {
    console.log(!citizenshipCountry && detectedCountry, {
      citizenshipCountry,
      detectedCountry,
    });
    if (!citizenshipCountry && detectedCountry) {
      setCitizenshipCountry(detectedCountry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detectedCountry]);

  return stepKey !== currentStep ? null : (
    <section className="flex flex-col w-full text-base font-display">
      <h2 className="self-center text-center text-stale-900">
        {t("WHAT_IS_YOUR_CITIZENSHIP")}
      </h2>
      <div className="flex flex-col justify-center px-2 mt-3 w-full whitespace-nowrap border-2 border-white border-solid bg-stane-50 text-zinc-500">
        <ReactFlagsSelect
          className="country-select"
          placeholder={t("SELECT_COUNTRY")}
          selected={citizenshipCountry}
          onSelect={(code) => setCitizenshipCountry(code)}
          searchable
        />
      </div>
      <div className="flex flex-col mt-10">
        <h2 className="self-center text-center text-stale-900">
          {t("SELECT_REASONS")}
        </h2>
        <div className="flex flex-col justify-center px-2 mt-3 w-full whitespace-nowrap border-2 border-white border-solid bg-stane-50 text-zinc-500">
          <Select
            className="w-full pb-1 relative"
            value={selectedReason}
            options={reasons}
            onChange={(newReason) => {
              if (newReason) {
                handleReasonChange(newReason as Reason);
              }
            }}
            styles={reasonsStyle}
            components={{
              IndicatorSeparator: () => null,
              Option,
              SingleValue,
              DropdownIndicator,
              Placeholder,
            }}
          />
        </div>
      </div>
      {errors.length > 0 && (
        <div className="text-red-600 rounded mb-6">
          <div className="font-bold mb-2">{t("FIX_ERROR_TO_CONTENU")}</div>
          {errors.map((error, i) => (
            <div key={`error-${i}`}>- {error}</div>
          ))}
        </div>
      )}
      <button
        className="flex justify-center items-center px-8 py-2 mt-10 text-lg font-medium leading-6 text-center bg-blue-600 shadow-sm rounded-[100px] text-stone-50"
        onClick={handleContinueClick}
      >
        <span className="flex gap-2">{t("START_NOW")}</span>
        <RightArrowIcon />
      </button>
    </section>
  );
}

export default InitialScreen;
