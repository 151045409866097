import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { ConfigContext } from "../../contexts/ConfigContext";

function PriceScreen({
  stepKey,
  currentStep,
}: {
  stepKey: string;
  travelReason: string;
  citizenshipCountry: string;
  currentStep: string;
  setCurrentStep: Function;
}) {
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation();

  const handleContinueClick = () => {
    window.location.href = config.formUrl;
  };

  return stepKey !== currentStep ? null : (
    <div className="p-4 bg-trasnparent">
      <div className="isolate mx-auto max-w-md flex flex-col justify-center items-center">
        <div className="relative bg-white ring-2 ring-midnight-800 flex flex-col w-full rounded-3xl p-8 xl:p-10 lg:z-10">
          <div className="text-midnight-800">
            <div className="flex items-center justify-center gap-x-4">
              <h3 className="font-black text-2xl leading-8 text-midnight-800 text-center mt-3">
                Canada eTA
              </h3>
            </div>
            <p className="mt-6 text-center items-baseline gap-x-1">
              <span id="service-price" className="text-6xl text-[15vw] md:text-8xl md:text-[3.5vw] font-bold tracking-tight">$59</span>
            </p>


            <p className="text-md mt-4 leading-6 text-midnight-800 text-center"
              dangerouslySetInnerHTML={{ __html: t("GOVERNMENT_FEE") }}>
            </p>


            <p className="mt-4 text-sm leading-6">{t("INCLUDES")}</p>
            <ul className="mt-5 space-y-3 text-sm leading-6">
              <li className="flex gap-x-2 text-left">
                <svg viewBox="-163.84 -163.84 1351.68 1351.68" className="h-5 w-4 flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000" strokeWidth="89.088">
                  <g id="SVGRepo_bgCarrier" strokeWidth="0" transform="translate(0,0), scale(1)">
                    <rect x="-163.84" y="-163.84" width="1351.68" height="1351.68" rx="675.84" fill="#fff" strokeWidth="0"></rect>
                  </g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="12.288"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#02213B"></path>
                  </g>
                </svg>
                {t("PERSONAL_VISA_CONSULTANT")}
              </li>
              <li className="flex gap-x-2 text-left">
                <svg viewBox="-163.84 -163.84 1351.68 1351.68" className="h-5 w-4 flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000" strokeWidth="89.088">
                  <g id="SVGRepo_bgCarrier" strokeWidth="0" transform="translate(0,0), scale(1)">
                    <rect x="-163.84" y="-163.84" width="1351.68" height="1351.68" rx="675.84" fill="#fff" strokeWidth="0"></rect>
                  </g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="12.288"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#02213B"></path>
                  </g>
                </svg>
                {t("24_7_LIMITLESS")}
              </li>
              <li className="flex gap-x-2 text-left">
                <svg viewBox="-163.84 -163.84 1351.68 1351.68" className="h-5 w-4 flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000" strokeWidth="89.088">
                  <g id="SVGRepo_bgCarrier" strokeWidth="0" transform="translate(0,0), scale(1)">
                    <rect x="-163.84" y="-163.84" width="1351.68" height="1351.68" rx="675.84" fill="#fff" strokeWidth="0"></rect>
                  </g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="12.288"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#02213B"></path>
                  </g>
                </svg>
                {t("FRIENDLY_DIGITAL_TOOLS")}
              </li>
              <li className="flex gap-x-2 text-left">
                <svg viewBox="-163.84 -163.84 1351.68 1351.68" className="h-5 w-4 flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000" strokeWidth="89.088">
                  <g id="SVGRepo_bgCarrier" strokeWidth="0" transform="translate(0,0), scale(1)">
                    <rect x="-163.84" y="-163.84" width="1351.68" height="1351.68" rx="675.84" fill="#fff" strokeWidth="0"></rect>
                  </g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="12.288"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#02213B"></path>
                  </g>
                </svg>
                {t("DECADES_OF_EXPERIENCE")}
              </li>
              <li className="flex gap-x-2 text-left">
                <svg viewBox="-163.84 -163.84 1351.68 1351.68" className="h-5 w-4 flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000" strokeWidth="89.088">
                  <g id="SVGRepo_bgCarrier" strokeWidth="0" transform="translate(0,0), scale(1)">
                    <rect x="-163.84" y="-163.84" width="1351.68" height="1351.68" rx="675.84" fill="#fff" strokeWidth="0"></rect>
                  </g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="12.288"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z" fill="#02213B"></path>
                  </g>
                </svg>
                {t("ONE_TIME_COURTESY")}
              </li>
              <li className="flex flex-col justify-center items-center">
                <button
                  onClick={handleContinueClick}
                  className="rounded-xl px-10 py-3 font-bold inline-block border-2 bg-jade-700 border-jade-700 text-white"
                >
                  {t("APPLY_NOW")}
                </button>
              </li>
            </ul>
          </div>
        </div >
      </div >
    </div>
  );
}

export default PriceScreen;
