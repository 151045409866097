import { useContext, useEffect, useState } from "react";
import "./App.css";
import EligibleScreen from "./components/screens/EligibleScreen";
import InitialScreen from "./components/screens/InitialScreen";
import NonEligibleScreen from "./components/screens/NonEligibleScreen";
import USAEligibleScreen from "./components/screens/USAEligibleScreen";
import PriceScreen from "./components/screens/PriceScreen";
import MXEligibleScreen from "./components/screens/MXEligibleScreen";
import { ConfigContext } from "./contexts/ConfigContext";
import { useTranslation } from "react-i18next";

function App({ initialConfig }: { initialConfig: any }) {
  const [citizenshipCountry, setCitizenshipCountry] = useState("");
  const [travelReason, setTravelReason] = useState("");
  const [currentStep, setCurrentStep] = useState("INITIAL");
  const { config, setConfig } = useContext(ConfigContext);
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    // Set config from initialization parameters
    setConfig({ ...config, ...initialConfig });
    console.log({ ...config, ...initialConfig });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleLocaleChange: EventListenerOrEventListenerObject = (event) => {
      const customEvent = event as CustomEvent<{ locale: string }>;
      setLanguage(customEvent.detail.locale);
    };

    // Attach the event listener when the component mounts
    document.addEventListener("locale-changed", handleLocaleChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("locale-changed", handleLocaleChange);
    };
  }, []);

  useEffect(() => {
    console.log(language);
    i18n.changeLanguage(language);
  }, [language, i18n]);
  
  return (
    <div className={`App canada-selection-form-v2 bg-white ${currentStep === "PRICE" ? "max-w-fit" : "max-w-3xl"} mx-auto rounded-lg p-5`}>
        <InitialScreen
          stepKey="INITIAL"
          travelReason={travelReason}
          citizenshipCountry={citizenshipCountry}
          setTravelReason={setTravelReason}
          setCitizenshipCountry={setCitizenshipCountry}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />

        <EligibleScreen
          stepKey="ELIGIBLE"
          travelReason={travelReason}
          citizenshipCountry={citizenshipCountry}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />

        <NonEligibleScreen
          stepKey="NON-ELIGIBLE"
          travelReason={travelReason}
          citizenshipCountry={citizenshipCountry}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />

        <USAEligibleScreen
          stepKey="USA-ELIGIBLE"
          travelReason={travelReason}
          citizenshipCountry={citizenshipCountry}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />

        <MXEligibleScreen
          stepKey="MX-ELIGIBLE"
          travelReason={travelReason}
          citizenshipCountry={citizenshipCountry}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />

        <PriceScreen
          stepKey="PRICE"
          travelReason={travelReason}
          citizenshipCountry={citizenshipCountry}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      </div> 
  );
}

export default App;
