import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
const host = process.env.REACT_APP_HOST_URL;
export function initializeLocale(lng: string) {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      lng,
      backend: {
        loadPath: `${host}/locales/{{lng}}.json`,
      },
      fallbackLng: "en",
      interpolation: {
        escapeValue: false,
        formatSeparator: ",",
      },
      react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ["br", "ul", "li"],
      },
    });
}

export default i18n;
