import { Icon } from "@iconify-icon/react";
import { useTranslation } from "react-i18next";

function USAEligibleScreen({
  stepKey,
  currentStep,
}: {
  stepKey: string;
  travelReason: string;
  citizenshipCountry: string;
  currentStep: string;
  setCurrentStep: Function;
}) {
  const { t } = useTranslation();
  return stepKey !== currentStep ? null : (
    <div className="non-eligible-screen text-lg">
      <div className="text-center text-gray-600 mb-3">
        <Icon className="text-5xl" icon="akar-icons:circle-alert" />
      </div>
      <div className="text-xl mb-6 text-center">
        <strong>{t("IMPORTANT")}</strong>
      </div>

      <div
        className="text-center mb-6"
        dangerouslySetInnerHTML={{
          __html: t("TRAVEL_AND_IDENTIFICATIONS_DOC", {
            documentsLink: "http://www.cbsa.gc.ca/travel-voyage/td-dv-eng.html",
          }),
        }}
      ></div>
    </div>
  );
}

export default USAEligibleScreen;
