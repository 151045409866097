import { Icon } from "@iconify-icon/react";
import { countries } from "../../data/countries";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { ConfigContext } from "../../contexts/ConfigContext";
import { ReactComponent as RightArrowIcon } from "../../svg/arrow-right.svg";

function EligibleScreen({
  stepKey,
  travelReason,
  citizenshipCountry,
  currentStep,
  setCurrentStep,
}: {
  stepKey: string;
  travelReason: string;
  citizenshipCountry: string;
  currentStep: string;
  setCurrentStep: Function;
}) {
  const { t } = useTranslation();
  const { config } = useContext(ConfigContext);

  const handleContinueClick = () => {
    if (config.features.showPricePage) {
      setCurrentStep("PRICE");
    } else {
      window.location.href = config.formUrl;
    }
  };

  return stepKey !== currentStep ? null : (
    <div className="eligible-screen text-lg">
      <div className="text-center text-gray-600 mb-3">
        <Icon className="text-5xl" icon="akar-icons:circle-check" />
      </div>

      <div className="text-xl text-center mb-6 font-bold">
        {t("COMPLETION_CONGRATS")}
      </div>

      <div className="flex justify-between items-center mb-3">
        <div>{t("YOUR_NATIONALITY")}</div>
        <div className="font-bold">
          {countries[citizenshipCountry as keyof typeof countries]}
        </div>
      </div>

      <div className="flex justify-between items-center mb-3">
        <div>{t("MAX_LENGTH_OF_STAY")}</div>
        <div className="font-bold">{t("180_DAYS")}</div>
      </div>

      <div className="flex justify-between items-center mb-3">
        <div>{t("RECOMMENDED_VISA_TYPE")}</div>
        <div className="font-bold">Canada ETA</div>
      </div>

      <div className="mb-6">
        <div className="mb-3">{t("GENERAL_VISA_INFO")}</div>
        <div className="italic">{t("ETA_INFO")}</div>
      </div>

      <div className="text-center flex justify-center">
        <button
          className="flex justify-center items-center px-8 py-2 mt-10  text-lg font-medium leading-6 text-center bg-blue-600 shadow-sm rounded-[100px] text-stone-50"
          onClick={handleContinueClick}
        >
          <span className="flex gap-2">{t("CONTINUE")}</span>
          <RightArrowIcon />
        </button>
      </div>
    </div>
  );
}

export default EligibleScreen;
