import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ConfigProvider } from "./contexts/ConfigContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { initializeLocale } from "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("canada-selection-form") as HTMLElement
);

window.InitializeCanadaSelectionForm = (
  formUrl: string,
  supportUrl: string,
  primaryColor: string,
  detectedCountry: string,
  language: string = "en",
  fallbackUrl: string="",
  features: object = {
    showPricePage: false, 
  }
) => {
  initializeLocale(language);
  const config = { formUrl, supportUrl, primaryColor, detectedCountry, fallbackUrl, features };
  root.render(
    <React.StrictMode>
      <ConfigProvider>
        <App initialConfig={config} />
      </ConfigProvider>
    </React.StrictMode>
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
